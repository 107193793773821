import React from "react"


import SEO from "../components/seo"

const NotFoundPage = () => (
  <h1>Hello Not found</h1>
)

export default NotFoundPage
